/* eslint-disable react/no-array-index-key */
import Plaatjie from '@ubo/plaatjie'
import ImageLightBox from 'components/elements/Misc/ImageLightbox'
import ParseContent from 'components/shared/ParseContent'
import React, { useState } from 'react'
import styled from 'styled-components'

export interface ProjectDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Projectdetail
  location?: any
}

const BigImage = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 780px;
  }
  @media (max-width: 991px) {
    height: 100%;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 426px;
  }
  @media (max-width: 991px) {
    height: 100%;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }

  & a {
    color: ${({ theme }) => theme.color.dark};
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const ProjectDetail: React.FC<ProjectDetailProps> = ({ fields }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const imageCount = fields?.galleryTwo?.length || 0

  const array = []

  array.push(...fields?.galerij, ...fields?.galleryThree, ...fields?.galleryTwo)

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 px-1 px-lg-3 pb-3 pb-lg-5">
            {fields?.galerij?.map((image, index) => (
              <button
                type="button"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <BigImage image={image} alt="" key={index} />
              </button>
            ))}
          </div>

          <div className="col-lg-10 mx-auto py-3 py-lg-5">
            <Content content={fields?.descriptionOne} />
          </div>

          <div className="col-12 px-1 px-lg-3 py-5">
            {fields?.galleryTwo?.map((image, index) => (
              <>
                {index !== 4 && (
                  <button
                    type="button"
                    className="col-6 mb-4"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                  >
                    <Image key={index} image={image} alt="" />
                  </button>
                )}
                {index === 4 && (
                  <button
                    type="button"
                    className="col-12"
                    onClick={() => setIsModalOpen(!isModalOpen)}
                  >
                    <BigImage key={index} image={image} alt="" />
                  </button>
                )}
              </>
            ))}
          </div>

          <div className="col-lg-10 mx-auto py-3 py-lg-5">
            <Content content={fields?.descriptionTwo} />
          </div>

          <div className="col-12 d-flex flex-wrap py-5">
            {fields?.galleryThree?.map((image, index) => (
              <button
                type="button"
                className="col-lg-6 mb-2"
                key={index}
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                <Image key={index} image={image} alt="" />
              </button>
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && <ImageLightBox fields={array} />}
    </section>
  )
}

export default ProjectDetail
